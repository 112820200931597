import React, { useState } from 'react';
import { Menu, Row, Col, Button, Form, Input } from 'antd';
import { route as makeUrl } from 'themes/route'
import Link from 'next/link'
import to from 'await-to-js';
import useBaseHook from 'themes/hooks/BaseHooks'

const Footer = () => {
  // Hover item 1
  const { t, redirect } = useBaseHook()
  const [selected, setSelected] = useState(1);
  

  const onFinish = () => {
    alert('home.footer.getInTouchSuccess')
  }

  return <>
    {/* <div id="bannerHoverChange">
      <div className="container">
        <div className="titleFeatureIndex">
          <h3><span className="lineTop"></span>{t("home.footer.title")}</h3>
          <h1>{t("home.footer.title2")}</h1>
          <p>{t("home.footer.subTitle")}</p>
        </div>
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <div className="itemBackgroundLaptop">
              <div className="img">
                <img src="/static/img/imgLaptop1.png" alt="dohu.vn" className={`img1 ${selected == 1 ? 'selected' : ''}`} />
                <img src="/static/img/imgLaptop2.png" alt="dohu.vn" className={`img2 ${selected == 2 ? 'selected' : ''}`} />
                <img src="/static/img/imgLaptop3.png" alt="dohu.vn" className={`img3 ${selected == 3 ? 'selected' : ''}`} />
              </div>
            </div>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <div className="itemContentInfoMain">
              <div className="itemContentLaptopMqn">
                <div className="ItemContentLaptop1" onMouseEnter={() => setSelected(1)}>
                  <Row gutter={24}>
                    <Col xs={6} md={3} lg={4} xl={4}>
                      <div className={`itemNumberMain ${selected == 1 ? "selected" : ""}`}>
                        <p>1</p>
                      </div>
                    </Col>
                    <Col xs={18} md={21} lg={20} xl={20}>
                      <div className={`infoMainDetailMqn ${selected == 1 ? "selected" : ""}`}>
                        <h1>{t("home.footer.step1")}</h1>
                        <p>{t("home.footer.step1Content")}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div onMouseEnter={() => setSelected(2)}>
                  <Row gutter={24}>
                    <Col xs={6} md={3} lg={4} xl={4}>
                      <div className={`itemNumberMain ${selected == 2 ? "selected" : ""}`}>
                        <p>2</p>
                      </div>
                    </Col>
                    <Col xs={18} md={21} lg={20} xl={20}>
                      <div className={`infoMainDetailMqn ${selected == 2 ? "selected" : ""}`}>
                      <h1>{t("home.footer.step2")}</h1>
                        <p>{t("home.footer.step2Content")}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div onMouseEnter={() => setSelected(3)}>
                  <Row gutter={24}>
                    <Col xs={6} md={3} lg={4} xl={4}>
                      <div className={`itemNumberMain ${selected == 3 ? "selected" : ""}`}>
                        <p>3</p>
                      </div>
                    </Col>
                    <Col xs={18} md={21} lg={20} xl={20}>
                      <div className={`infoMainDetailMqn ${selected == 3 ? "selected" : ""}`}>
                      <h1>{t("home.footer.step3")}</h1>
                        <p>{t("home.footer.step3Content")}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div> */}
    {/* <div id="contactMainProMqn">
      <div className="container">
        <div id="contactForm">
          <div className="titleContactMain">
            <h1>{t("home.footer.needSomeAdvice")}</h1>
          </div>
          <Row>
            <div className="itemFormContactMain">
              <Form name="contact" onFinish={onFinish} initialValues={{ remember: true }}>
                <Row gutter={24}>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <Form.Item name="username" rules={[{ required: true, message: t('form.required') }]}>
                      <Input placeholder= {t("home.footer.username")} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <Form.Item name="phonenumber" rules={[{ required: true, message: t('form.required') }]}>
                      <Input placeholder= {t("home.footer.phonenumber")} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <Form.Item name="email" rules={[{ required: true, message: t('form.required') }]}>
                      <Input placeholder= {t("home.footer.email")} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <Form.Item name="company" rules={[{ required: true, message: t('form.required') }]}>
                      <Input placeholder= {t("home.footer.company")} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={24} xl={24}>
                    <Form.Item name="messager" rules={[{ required: true, message: t('form.required') }]}>
                      <Input.TextArea placeholder= {t("home.footer.messager")} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" className="buttonContact" htmlType="submit">
                  {t("home.footer.getInTouch")}
              </Button>
                </Form.Item>
              </Form>
            </div>
          </Row>
        </div>
      </div>
    </div> */}
    {/* <div id="startCampsiteMqn">
      <div className="container">
        <div className="infoStartCampsMqn">
          <Row>
            <Col xs={24} span="16">
              <h1>{t("home.footer.infoStartCamps1")}</h1>
              <h2>{t("home.footer.infoStartCamps2")}</h2>
            </Col>
            <Col xs={24} span="8">
              <div>
                <Button onClick={() => redirect('frontend.owner.register')} className="butnAccount">{t("home.index.createAccount")}</Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div> */}
    <div id="footerMainProMqn">
      <div className="container">
        <div className="header2">
          <Row>
            <Col xs={24} md={24} lg={9} xl={9}>
              <div className="logo">
                <a href="/"><img src="/static/img/logoDohuWhite.png" alt="dohu.vn" /></a>
                <p>{t("home.footer.mainContent")}</p>
                <p>{t("home.footer.mainContentPs")}</p>
              </div>
            </Col>
            <Col xs={24} md={10} lg={7} xl={8}>
              <div className="menuMainMqn">
                <h1>{t("home.footer.category")}</h1>
                <ul>
                  <li><Link {...makeUrl("frontend.home.index")}>{t('common:home.menu.platform')}</Link></li>
                  <li><Link {...makeUrl("frontend.home.product")}>{t('common:home.menu.product')}</Link></li>
                  <li><Link {...makeUrl("frontend.home.blog")}><a>{t('common:home.menu.contactUs')}</a></Link></li>
                </ul>
              </div>
            </Col>
            <Col xs={24} md={14} lg={8} xl={7}>
              <div className="itemContact">
                <h1>{t("home.footer.contact")}</h1>
                <div className="itemContacFooter">
                  <img src="/static/img/placeholdersWhite.svg" alt="dohu.vn" />
                  <p>{t("home.footer.address")}</p>
                </div>
                <div className="itemContacFooter">
                  <img src="/static/img/phoneW.svg" alt="dohu.vn" />
                  <p>{t("home.footer.tel")}</p>
                </div>
                <div className="itemContacFooter">
                  <img src="/static/img/mailing.svg" alt="dohu.vn" />
                  <p>{t("home.footer.mail")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="headerCopyRight">
          <Row>
            <Col xs={24} md={24} lg={24} xl={24} style={{display: 'flex', justifyContent: 'center'}}>
              <p>© DOOHUB Advertising and High Techlonogy Joint Stock Company</p>
            </Col>

            {/* <Col xs={14} md={7} lg={7} xl={2}>
              <div className="iconSocialFooter">
                <Row gutter={24}>
                  <Col span={12}>
                    <img src="/static/img/facebook.svg" alt="dohu.vn" />
                  </Col>
                  <Col span={12}>
                    <img src="/static/img/youtube.svg" alt="dohu.vn" />
                  </Col>
                </Row>
              </div>
            </Col> */}
          </Row>
        </div>
      </div>
    </div>
  </>
}

export default Footer